import React from 'react';
import classes from "./Banner.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import {Trans, useTranslation} from "react-i18next";
import imgBanner from "../../../assets/img/VacanciesPage/img-banner.webp";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={`${classes.banner}`}>
            <div className={`container ${classes.banner__container}`}>
                <div className={`${classes.banner__wrap}`}>
                    <h1 className={`${classes.banner__title}`}><Trans>{t("vacancies_title_1")}</Trans></h1>
                    <p className={`${classes.banner__descr}`}>{t("vacancies_descr_1")}</p>
                    <div className={`${classes.banner__controls}`}>
                        <Button>{t("btn_respond")}</Button>
                    </div>
                    <div className={`${classes.banner__preview}`}>
                        <img className={`${classes.banner__previewImg}`} src={ imgBanner } alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner;
