import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo-home.png";
import {Trans, useTranslation} from "react-i18next";

const Footer = ({fill, enjoy}) => {
    const {t} = useTranslation();
    const {hostname} = document.location;
    return (
        <div>
            <footer className={`${classes.footer}`}>
                <div className={`container ${classes.footer__container}`}>
                    <div className={classes.logo}>
                        <img src={logo} alt=""/>
                    </div>
                    <div className={classes.info}>
                        <div className={classes.contacts}>
                            <p>
                                {t("footer_1")}
                            </p>
                            <a href={`mailto:miradotradingpro@gmail.com`}>
                                miradotradingpro@gmail.com
                            </a>
                            <a href="tel:+7(916)4851240">
                                +7(916)-485-1240
                            </a>
                        </div>
                        <div className={classes.docs}>
                            <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                                {t("footer_2")}
                            </a>
                            <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                                {t("footer_3")}
                            </a>
                            <a href="/docs/AML_POLICY.pdf" target="_blank">
                                {t("footer_4")}
                            </a>
                        </div>
                    </div>
                    <div className={classes.disclaimer}>
                        <p className={classes.disclaimerTitle}>
                            {t("footer_5")}
                        </p>
                        <p>
                            {t("footer_disclaimer_1")}
                            <br/>
                            {t("footer_disclaimer_2")}
                            <br/>
                            {t("footer_disclaimer_3")}
                            <br/>
                            {t("footer_disclaimer_4")}
                            <br/>
                            {t("footer_disclaimer_5")}
                            <br/>
                            {t("footer_disclaimer_6")}
                        </p>
                        <p className={classes.rights}>
                            {t("footer_copyright")}
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
