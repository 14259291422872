import React, {useLayoutEffect, useRef} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import FaqTabs from '../FaqTabs/FaqTabs';
import classes from "./Faq.module.scss";

import icon1 from "../../../assets/img/main/icon-tel.png";
import icon2 from "../../../assets/img/main/icon-email.png";
import icon3 from "../../../assets/img/main/icon-address.png";
import {Trans, useTranslation} from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const Faq = () => {
    const main = useRef();
    const { t } = useTranslation();
    const {hostname} = document.location;

    const faqTabs = [
        {
            title: t("main_faq_title_2"),
            text: t("main_faq_descr_1"),
        },
        {
            title: t("main_faq_title_3"),
            text: t("main_faq_descr_2"),
        },
        {
            title: t("main_faq_title_4"),
            text: t("main_faq_descr_3"),
        },
        {
            title: t("main_faq_title_5"),
            text: t("main_faq_descr_4"),
        },
        {
            title: t("main_faq_title_6"),
            text: t("main_faq_descr_5"),
        }
    ];

    return (
        <section className={`${classes.faq}`} ref={main}>
            <div className={`container ${classes.faq__container}`}>
                <header className={`${classes.faq__header}`}>
                    <h2 className={`${classes.faq__title}`}>{t("main_faq_title_1")}</h2>
                </header>
                <div className={`${classes.faq__content}`}>
                    <FaqTabs tabs={ faqTabs }></FaqTabs>
                    <div className={`${classes.faq__contacts}`}>
                        <h3 className={`${classes.faq__contactsTitle}`}><Trans>{t("main_faq_title_7")}</Trans></h3>
                        <p className={`${classes.faq__contactsDescr}`}>{t("main_faq_descr_6")}</p>
                        <p className={`${classes.faq__contactsDescr}`}>{t("main_faq_descr_7")}</p>
                        <ul className={`${classes.faq__contactsList}`}>
                            <li className={`${classes.faq__contactsItem}`}>
                                <a href={`mailto:support@${hostname}`}>
                                    support@{hostname}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq;