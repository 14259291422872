import React from 'react';

import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Feedback from "../../components/MarketsPage/Feedback/Feedback";
import Banner from "../../components/MarketsPage/Banner/Banner";
import Vacancies from "../../components/MarketsPage/Vacancies/Vacancies";

const VacanciesPage = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Vacancies</title>
                <meta name="title" content="Trading Strategies That Work. Mirado - Strategies Your Trading Skills and Make Informed Decisions" />
                <meta property="og:title" content="Trading Strategies That Work. Mirado - Strategies Your Trading Skills and Make Informed Decisions" />
                <meta property="twitter:title" content="Trading Strategies That Work. Mirado - Strategies Your Trading Skills and Make Informed Decisions" />

                <meta name="description" content="On Mirado, you can learn effective trading strategies to improve your trading skills and make more informed decisions. Discover a variety of tried-and-true trading strategies, risk management techniques, and trading hints to help you improve your trading performance. Explore our Trading Strategies page and use Mirado to take your trading to the next level." />
                <meta property="og:description" content="On Mirado, you can learn effective trading strategies to improve your trading skills and make more informed decisions. Discover a variety of tried-and-true trading strategies, risk management techniques, and trading hints to help you improve your trading performance. Explore our Trading Strategies page and use Mirado to take your trading to the next level." />
                <meta property="twitter:description" content="On Mirado, you can learn effective trading strategies to improve your trading skills and make more informed decisions. Discover a variety of tried-and-true trading strategies, risk management techniques, and trading hints to help you improve your trading performance. Explore our Trading Strategies page and use Mirado to take your trading to the next level." />

                <meta property="og:image" content="preview.png" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="preview.png" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className={`ovf-hidden`}>
                <Header />
                <Banner />
                <Vacancies />
                {/*<Feedback />*/}
                <Footer />
            </div>
        </HelmetProvider>
    )
}

export default VacanciesPage;
